import request from '@/request'

export const getSolutions = (data = {}) => {
    return request({
      url: '/admin/system/get_solutions',
      method: 'get',
      params: data
    })
}
export const updateSolutions = (data = {}) => {
  return request({
    url: '/admin/system/update_solutions',
    method: 'post',
    data
  })
}
export const removeSolutions = (data = {}) => {
  return request({
    url: '/admin/system/remove_solutions',
    method: 'post',
    data
  })
}
