<template>
     <div class="flex_layout h100">
        <div class="flex_top">
            <div class="query flex">
                <a-space>
                    <div class="query_box">
                        <a-button class="primary btn" @click="add">Add</a-button>
                    </div>
                </a-space>
            </div>
        </div>
        <div class="flex_body">
            <a-spin :spinning="spinning">
                <a-empty v-if="solutions.length == 0" />
                <a-row :gutter="20" v-else>
                    <a-col :span="8" v-for="(item, index) in solutions" :key="index">
                        <div class="box_cell">
                            <div class="title flex">
                                <span>Solution {{index+1}}</span>
                                <a-button type="primary" danger size="small" shape="circle" @click="remove(index)">
                                    <template #icon>
                                        <component :is="$antIcons['CloseOutlined']" />
                                    </template>
                                </a-button>
                            </div>
                            <div class="body">
                                <a-form :model="item" :label-col="labelCol" :wrapper-col="wrapperCol">
                                    <a-form-item label="Heading">
                                        <a-input v-model:value="item.heading" placeholder="Heading" />
                                    </a-form-item>
                                    <a-form-item label="Sub heading">
                                        <a-textarea v-model:value="item.sub_heading" :rows="5" placeholder="Sub heading" />
                                    </a-form-item>
                                    <a-form-item label="Icon">
                                        <a-upload :customRequest="(file) => customRequest(index, file.file)" :showUploadList="false">
                                            <img :src="item.icon_url" v-if="item.icon_url.length > 0" class="icon">
                                            <a-button size="small">
                                            <component :is="$antIcons['UploadOutlined']" />
                                            Upload
                                            </a-button>
                                        </a-upload>
                                    </a-form-item>
                                    <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                                        <a-button type="primary" @click="updateSolutions(item)">Submit</a-button>
                                    </a-form-item>
                                </a-form>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </a-spin>
        </div>
    </div>
</template>
<script>
import { message } from 'ant-design-vue'
import { upload } from '@/apis/system'
import { getSolutions, updateSolutions, removeSolutions } from '@/apis/solutions'
export default {
    name: 'Website setting',
    data(){
        return {
            solutions: [],
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            spinning: false
        }
    },
    methods: {
        add(){
            this.solutions.push({
                id: 0,
                heading: '',
                sub_heading: '',
                icon: '',
                icon_url: ''
            })
        },
        async remove(index){
            const id = this.solutions[index].id
            if(id == 0){
                this.solutions.splice(index ,1)
            }else{
                this.spinning = true
                const res = await removeSolutions({id})
                this.spinning = false
                if(res){
                    message.success('success')
                    this.getSolutionsHandler()
                }
            }
        },
        async updateSolutions(item){
            this.spinning = true
            const res = await updateSolutions(item)
            this.spinning = false
            if(res){
                message.success('success')
                this.getSolutionsHandler()
            }
        },
        /**
         * 上传
         */
        async customRequest(index, file){
            this.spinning = true
            const res = await upload(file)
            this.spinning = false
            if(res){
                this.solutions[index].icon = res.name
                this.solutions[index].icon_url = res.file_name
            }
        },
        /**
         * 获取解决方案
         */
        async getSolutionsHandler(){
            this.spinning = true
            const res = await getSolutions()
            this.spinning = false
            if(res){
                this.solutions = res.list
            }
        }
    },
    created(){
        this.getSolutionsHandler()
    }
}
</script>
<style lang="stylus" scoped>
.icon
    width 80px
    display block
    margin-bottom 10px
</style>